<template>
  <div class="little-input-wrapper" :class="lightTheme && 'wrapper--light'">
    <div class="little-input">
      <input
        required
        autocomplete="off"
        v-model="code[0]"
        @input="typeCode"
        @keyup.delete="handleBackSpace"
        pattern="[0-9]*"
        :type="device == 'other-devices' && 'number'"
      />
      <div class="underline" />
    </div>
    <div class="little-input">
      <input
        v-model="code[1]"
        required
        autocomplete="off"
        @input="typeCode"
        @keyup.delete="handleBackSpace"
        pattern="[0-9]*"
        :type="device == 'other-devices' && 'number'"
      />
      <div class="underline" />
    </div>
    <div class="little-input">
      <input
        v-model="code[2]"
        required
        autocomplete="off"
        @input="typeCode"
        @keyup.delete="handleBackSpace"
        pattern="[0-9]*"
        :type="device == 'other-devices' && 'number'"
      />
      <div class="underline" />
    </div>
    <div class="little-input">
      <input
        v-model="code[3]"
        required
        autocomplete="off"
        @input="typeCode"
        @keyup.delete="handleBackSpace"
        pattern="[0-9]*"
        :type="device == 'other-devices' && 'number'"
      />
      <div class="underline" />
    </div>
    <div class="little-input">
      <input
        v-model="code[4]"
        required
        autocomplete="off"
        @input="typeCode"
        @keyup.delete="handleBackSpace"
        pattern="[0-9]*"
        :type="device == 'other-devices' && 'number'"
      />
      <div class="underline" />
    </div>
    <div class="little-input">
      <input
        v-model="code[5]"
        required
        autocomplete="off"
        @input="typeCode"
        @keyup.delete="handleBackSpace"
        pattern="[0-9]*"
        :type="device == 'other-devices' && 'number'"
      />
      <div class="underline" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInputLittle',
  props: {
    lightTheme: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      code: ['', '', '', '', '', '']
    }
  },
  methods: {
    faNumbersToEnNumbers(n) {
      return n.replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
    },
    arNumbersToEnNumbers(n) {
      return n.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
    },
    typeCode(e) {
      // Set a max number
      const maxLength = 1

      let n = e.data
      n = this.arNumbersToEnNumbers(n)
      n = this.faNumbersToEnNumbers(n)

      /**
       * e.data => the pressed key
       * e.target.valut => the content of input
       */
      if (/[0-9]/g.test(n)) {
        // finding input index
        const MainParentNodes = e.target.parentNode.parentNode.children
        const nodeIdx = Array.from(MainParentNodes).indexOf(e.target.parentNode)
        const nextSibling = MainParentNodes[nodeIdx + 1]

        this.code[nodeIdx] = n

        // check maxLength
        let inputText = e.target.value
        if (inputText.length > maxLength) {
          // inputText = inputText.substr(1, 2)
          this.code[nodeIdx] = n
        }

        // what to do for last input
        if (nodeIdx === 5) {
          return this.$emit('finish-type', this.code.join(''))
        }
        nextSibling.querySelector('input').focus()
      }
    },
    handleBackSpace(e) {
      // find main items
      const MainParentNodes = e.target.parentNode.parentNode.children
      const nodeIdx = Array.from(MainParentNodes).indexOf(e.target.parentNode)

      // handle ignoring of first item
      if (nodeIdx === 0) return

      // main
      const prevSibling = MainParentNodes[nodeIdx - 1]
      prevSibling.querySelector('input').focus()
    }
  },
  computed: {
    device() {
      return this.$store.state.device
    }
  }
}
</script>

<style lang="scss" scoped>
.little-input-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  direction: ltr;
}

.little-input {
  width: 30px;
  height: 40px;
  margin: 0 5px;
  position: relative;

  input {
    font-size: 30px;
    height: 100%;
    width: 100%;
    color: #000;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    text-align: center;
    padding: 2px 4px 0px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:focus {
      ~ div.underline::before {
        transform: translateX(0);
      }
    }
    &:focus,
    &:valid {
      ~ label.decrease {
        transform: translateY(-25px);
        font-size: 15px;
      }
      ~ label.remove {
        opacity: 0;
      }
      ~ label.static {
      }
    }
  }

  .underline {
    overflow: hidden;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 2px;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      background-color: #c69d4e;
      width: 100%;
      right: 0;
      height: 100%;
      transform: translateX(100%);
      transition: transform 0.5s ease;
    }
  }
}
.wrapper--light .little-input input {
  border-bottom-color: #444;
  color: white;
}
</style>
