<script setup>
const rules = [
  {
    id: 1,
    title: 'شرايط اوليه استفاده از خدمات',
    details: [
      'در زمان تکميل فرم ویپون، با تيک زدن بخش مربوط به قبول شرايط استفاده از خدمات نشان داده ايد که مفاد این توافقنامه به صورت کامل مطالعه و دریافت شده و تمامی موارد آن مورد قبول شماست. (تیک نزدن بخش مربوط به قبول شرایط ادامه ثبت نام را برای شما غیرممکن می سازد و نمی‌توانيد از ویپون استفاده کنيد. بنابراين استفاده از ویپون نشان می‌دهد که شما شرايط و توافق‌های استفاده از سيستم را قبول کرده ايد) و شرکت فناوری ارتباطات ورنا اروند می‌تواند در مراجع قضایی، صنفی و غیره به آن استناد نماید.',
      'عضويت مجموعه حقوقی و يا حقيقی ای که شما از طرف آن‌ها فرم‌های عضويت در ویپون را تکميل کرده ايد نشان می‌دهد که آن‌ها نيز شرايط و توافقات استفاده از سيستم و همچنين سياست‌های حفظ حريم خصوصی را قبول دارند و شما از طرف آن‌ها مجاز بوده و يا اختيار داشته ايد که اين توافق را انجام دهيد'
    ]
  },
  {
    id: 2,
    title: 'تعاريف',
    details: [
      'حق اجرا: ویپون به عنوان يک خدمت در اختيار کاربران قرار داده می‌شود و حق اجرا حق استفاده عملی و کاربردی از آن در محیط‌های رایانه‌ای نرم افزار است، از اين پس هر کجا در اين توافقنامه واژه خدمت آورده شده است منظور استفاده از نرم افزار ویپون است.',
      'حساب کاربری: هر شخص حقيقی و يا حقوقی با تکميل فرم ثبت نام از طریق سايت www.vipon.ir می تواند برای خود يک حساب کاربری شامل یک شناسه و گذرواژه ايجاد کرده و از خدمت ویپون استفاده کند.',
      'مشتری يا صاحب حساب يا شما: هر شخص حقيقی و يا حقوقی که در سایت www.vipon.ir ثبت نام کرده است و از خدمت ویپون استفاده می‌کند.',
      'ویپون يا ما: منظور شرکت فناوری ارتباطات ورنا اروند به عنوان پدید آورنده ویپون است.',
      'اطلاعات مشتری: مشتری در ضمن استفاده از اين خدمت اطلاعاتی را در ویپون وارد می‌کند که در اين توافقنامه اطلاعات مشتری ناميده می‌شود.',
      'کاربر: هر شخصی که مدیر سیستم مشتری برای وی شناسه و گذر‌واژه تعریف کرده و با وارد کردن شناسه و گذر‌واژه خود وارد ویپون شود؛ کاربر ناميده می‌شود.'
    ]
  },
  {
    id: 3,
    title: 'ارائه خدمات و استفاده از خدمات',
    details: [
      'صاحب حساب و تمامی کاربران، مسئول حفاظت از امنيت حساب کاربری خود می‌باشند که شامل مواردی است مانند: انتخاب گذرواژه ای که سايرين نتوانند حدس بزنند، تغيير گذر‌‌‌واژه درصورتی که احساس کنند شخص ديگری گذر‌واژه آن‌ها را می‌داند، محرمانه نگه داشتن اطلاعات شناسه و گذر‌واژه.',
      'مسئوليت از دست رفتن و يا آسيب‌های احتمالی به اطلاعات مشتری كه به دليل کوتاهی در حفظ امنيت و محرمانه نگه داشتن اطلاعات شناسه و گذر‌واژه مشتری اتفاق افتاده، بر عهده مشتری است و مشتری می‌پذیرد که ویپون هيچ مسئوليتی در اين مورد ندارد. تشخیص این موضوع با استفاده از لاگ ثبت شده در سیستم و بر عهده ویپون خواهد بود. توجه: لازم به ذكر است با توجه به نحوه رمز گذاری، دسترسی به گذر‌واژه مشتری از طرف هيچ كس حتی كاركنان ویپون امكان پذير نيست. براي رفع مشكلات و نگهداری از سيستم، مدير سيستم ویپون می‌تواند با گذرواژه و شناسه خودش به تمامی حساب‌های كاربری وارد شود و مدير سيستم مشتری می‌تواند از لاگ هايی كه به وی نمايش داده می‌شود ورود مدیر سیستم ویپون را نيز مشاهده كند. مدیر سیستم ویپون در دو حالت وارد حساب مشتری می‌شود',
      'در مواردی که مشکلی برای مشتری در استفاده از خدمت به وجود آمده و رفع آن نیازمند ورود به حساب کاربری وی است. این موارد به درخواست مشتری انجام می‌شود.',
      'در مواردی که در نتیجه ایجاد تغییری در ارائه خدمات چه از لحاظ سخت افزاری و چه از لحاظ نرم افزاری لازم باشد صحت کارکرد خدمات و نرم افزار از طریق ورود به حساب‌های کاربری کنترل شود.',
      'در هنگام تمدید حساب توسط مشتری با توجه به اینکه ایشان قبلا از خدمات بهره برداری نموده و با ویژگی‌های آن آشنا است، این خرید مجدد مشمول ماده ۳۷ قانون تجارت الکترونیک نمی‌شود.'
    ]
  },
  {
    id: 4,
    title: 'مسئوليت‌های ویپون در ارائه خدمات',
    details: [
      `ویپون تمامی تلاش خود را در راستای ایجاد بستر پیوسته استفاده مشتریان از خدمات مصروف می‌دارد (با ضریب اطمینان ۹۸% در ماه)، موارد خاص و استثنائات این تعهد عبارتند از:
      زمان‌های برنامه ريزی شده برای انجام فعاليت‌های نگهداری از سيستم كه حداقل از ۲۴ ساعت قبل به مشتری اطلاع رسانی خواهد شد.
      شرايط فورس ماژور مانند سيل، زلزله و….`,
      'ویپون پشتیبانی لازم را بدون دریافت هزینه از طریق تماس و چت به تمامی دارندگان حساب ارائه می‌كند.',
      'پشتیبانی شامل به روز‌آوری نرم افزار و پاسخ گویی به سوالات و رفع مشکلات احتمالی است که در حین استفاده از سیستم برای کاربران به وجود می‌آید.',
      'پشتیبانی شامل آموزش به کاربران جدید، ساخت گزارشات جدید و تغییرات اختصاصی در نرم افزار نمی‌شود.',
      'با توجه به ماهیت پردازش ابری، کاربران ویپون همواره از آخرین نسخه نرم افزار استفاده خواهند کرد.',
      'برای پاسخگویی به سوالات مشتریان و حل مشکلات احتمالی که ممکن است در حین استفاده از نرم افزار به وجود آید، کاربران ویپون می‌توانند سوالات و یا مشکلات خود را از طریق تماس با شماره ۰۲۱۹۱۳۱۰۳۳۳اعلام کرده و یا به صورت تیکت پشتیبانی به ویپون اعلام کرده تا تیم پشتیبانی حداکثر ظرف مدت ۴ ساعت در ایام کاری (شنبه تا پنج شنبه ۸:۳۰ تا ۲۴ بامداد ) برای پاسخگویی به سوالات و حل مشکلات با آن‌ها ارتباط برقرار کنند.',
      'با توجه به این امر که مدیر سیستم مشتری به کاربران اشتراک خود اجازه ثبت تیکت برای پشتیبانی ویپون را داده است، درخواستی که به وسیله ثبت تیکت از سمت مشتری به تیم پشتیبانی ویپون اعلام گردد رسمی تلقی گردیده و به آن ترتیب اثر داده خواهد شد.',
      'رعایت قوانين جمهوری اسلامی ايران در ارائه خدمات'
    ]
  },
  {
    id: 5,
    title: 'مقررات استفاده از حساب کاربری توسط مشتری',
    details: [
      'صاحب حساب کاربری مسئول محتويات تمامی اطلاعاتی است که خود و يا ساير کاربرانش که به آن‌ها حق دسترسی داده است در حين استفاده از خدمت در ویپون وارد و ثبت کرده اند. ویپون هيچ مسئولیتی نسبت به انتشار اطلاعات محرمانه، غير قانونی، غيرواقعی و دارای حق انتشار به وسيله کاربران را ندارد.',
      'حساب کاربری به منظور انجام کارهای تجاری قانونی در اختيار صاحب حساب قرار داده شده است. هرگونه تلاش برای هک کردن سيستم، انجام مهندسی معکوس، کپی برداری و نيز تلاش به هر نحوی در جهت ايجاد خدشه در امنيت و کارکرد خدمت و يا ساير جنبه های خدمات مرتبط با آن پيگرد قانونی داشته، منجر به حذف حساب کاربری شده و هيچ گونه وجهی به صاحب حساب مسترد نخواهد شد.',
      'صاحب حساب نبايد از طريق حساب خود اقدام به ثبت فروش های غیر واقعی نموده و يا اجازه دهد که شخص ديگری با استفاده از اطلاعات حساب وی اين کار را انجام دهد. ارسال پیامک های غیر واقعی از طريق حساب باعث می‌شود که حساب کاربری به سرعت به حالت تعليق در آمده و سپس عضويت صاحب حساب کاربری در سيستم به صورت يک جانبه از طرف ویپون لغو شود. اقدامات ویپون در این مورد کاملا مورد قبول صاحب حساب بوده و هر گونه ادعا و اعتراض بعدی نسبت به آن سلب و اسقاط شده است.',
      'تمامی اطلاعيه‌ها از طريق پیامک براي صاحب حساب ارسال خواهد شد و صاحب حساب باید با کسب اطمينان از صحت شماره تماس اعلام شده به ویپون و نيز خارج کردن شماره پیامک از ليست سیاه تلفن همراه خود بستری فراهم کند که قادر به دريافت تمامی پیامک‌های ارسال شده برای خود باشد.',
      'مشتری باید تمامی قوانين جمهوری اسلامی ايران خصوصا “قانون تجارت الکترونیکی”، “قانون جرائم رایانه‌ای” و “قانون حمایت از حقوق پدید‌آورندگان نرم افزار‌های رایانه‌ای” را رعايت نماید و هرگونه مسئوليت ناشی از عدم رعايت اين قوانين توسط صاحب حساب و يا ساير كاربران وی به عهده مشتری است و ویپون هيچ مسئوليتی در اين مورد نخواهد داشت.'
    ]
  },
  {
    id: 6,
    title: 'حقوق و مالكيت معنوی و مالكيت اطلاعات',
    details: [
      'ویپون متعهد می‌شود انبار داده و پلت فرمی برای ذخيره اطلاعات مشتری و انجام نيازهای ارتباطی وی با ضریب اطمینان ۹۸% در ماه فراهم كند.ویپون هيچ نظارت و مميزی بر محتوای اطلاعات وارد شده توسط مشتری نداشته و هيچ گونه ادعا و يا مسئوليتی نسبت به اطلاعات مشتری ندارد.',
      'تمامی اطلاعاتی كه توسط كاربران مشتری در حين استفاده از خدمت در ویپون ثبت می‌شود متعلق به همان مشتری بوده و ویپون هيچگونه مالکيتی نسبت به اين اطلاعات ندارد. مشتری به تنهايی مسوول دقت، کيفيت، يکپارچگی، قانونی بودن، قابل اتکا بودن، مناسب بودن و استفاده مناسب از داده‌های خود می‌باشد. و ویپون هيچگونه مسئوليتی در قبال حذف، تصحيح، صدمه ديدن، خرابی و يا مشکل در ذخيره شدن درست داده‌های مشتری ندارد.',
      'در صورت عدم تمدید اشتراک/فسخ/خاتمه/لغو اين توافقنامه حق استفاده از نرم افزار برای مشتری فورا متوقف می‌شود. اطلاعات مشتری تا ۹۰ روز به صورت قابل مشاهده روی سرورهای ویپون در دسترس مشتری می‌باشد و آخرین نسخه پشتیبان اطلاعات مشتری بنا به درخواست مشتری در اختیار وی قرار می‌گیرد، پس از گذشت ۹۰ روز اطلاعات مشتری حذف شده و به هیچ عنوان قابل بازگشت نمی‌باشد.',
      'ام تجاری ویپون ، نشان تجاری، سايت شركت، خدمات و هرگونه متون و تصاوير و ويژگی‌های خدمات منحصر به شركت فناوری ارتباطات ورنا اروند بوده و مالكيت معنوی آن متعلق به اين شركت است.',
      'در راستای حمايت از حقوق و مالكيت‌های معنوی انجام فعاليت‌های زير براي مشتری مجاز نيست. هر‌گونه مهندسی معكوس بر روی نرم افزار و خدمات فراتر از حدی كه قانون جمهوری اسلامی ايران ذكر كرده است. استفاده از نرم افزار، خدمات ارائه شده، سايت يا هر جزء آن بگونه اي كه موجب تخلف در حقوق مالكيت معنوی شود. انتقال هر‌نوع كرم نرم افزاري يا ويروس يا هر كد نرم‌افزاری مخرب در زمان استفاده از خدمت و وب سايت ویپون. هر گونه هک كردن نرم افزار و يا وب سايت ویپون.. شبيه سازی نام های تجاری ویپون، نشان تجاری، سايت شركت، خدمات و هر گونه متون و تصاوير و ويژگی های خدمات فناوری ارتباطات ورنا اروند. هر گونه فعاليت مخالف قوانين جمهوری اسلامی ايران.'
    ]
  },
  {
    id: 7,
    title: 'محرمانگی اطلاعات',
    details: [
      'شركت فناوری ارتباطات ورنا اروند به اسرار تجاری و حقوق مالكيت معنوی مشتريان نسبت به اطلاعات و ارتباطات بارگذاری و ثبت شده در برنامه احترام گذاشته، به صورت پيشگيرانه فعاليت‌های مورد نياز جهت حفاظت از محرمانگی اين اطلاعات را انجام می‌دهد.',
      'اطلاعات مشتری به هيچ شخصيت حقيقی و يا حقوقی ثالثی مگر با حكم مراجع قضايی نمايش داده نمی‌شود.',
      'در شرایط مشخص شده و بعد از زمان‎‌های ذكر شده در توافقنامه اطلاعات مشتری به صورت غيرقابل بازگشت حذف می‌شود.',
      'هر مشتری می‌تواند در هر دوره سه ماهه یک‌بار به صورت رایگان اطلاعات خود را بر روی وسایل سخت افزاری نظیر cd یا dvd دریافت کند. برای این منظور پس از درخواست مدير عامل مشتری حقوقی كه از طريق ايميل و نامه رسمی(در سربرگ به همراه مهر و امضای) به ورنا اروند ارسال می شود اطلاعات به نماينده معرفی شده (در نامه و ايميل) از سوی مشتری تحويل می‌شود.',
      'بندهای مربوط به محرمانگی اطلاعات مشتری دائمی تلقی می‌شود. (حتی پس از فسخ اين توافقنامه)',
      'نحوه رمز‌گذاری به صورتی است كه دسترسی به گذر‌واژه مشتری از طرف هيچ كس حتی كاركنان ویپون امكان پذير نيست. لازم به ذكر است برای رفع مشكلات و نگهداری از سيستم، مدير سيستم ویپون می‌تواند با شناسه و گذر‌واژه خودش به تمامی حساب‌های كاربری وارد شود و مدير سيستم مشتری می‌تواند از لاگ‌هایی كه برای وی پیامک میشود اين نوع ورود‌ها را نيز مشاهده كند.'
    ]
  },
  {
    id: 8,
    title: 'مقررات مربوط به قيمت‌ها و نحوه پرداخت',
    details: [
      'پرداخت به منظور استفاده از خدمات، از طريق واريز به حساب به صورت اينترنتی يا غير اينترنتی و در دوره های زمانی ۳ماهه-۶ماهه-۹ ماهه و یا سالانه انجام می‌شود. اولين پرداخت در پايان دوره آزمايشی انجام می‌شود.',
      'ویپون در هر پرداخت صورت حسابی در اختيار مشتری قرار می‌دهد.',
      'قیمت‌های اعلامی در سایت به روز بوده و ویپون می‌تواند در صورت نياز تعرفه‌های استفاده از نرم افزار را افزایش دهد. هزينه های ادامه استفاده از سيستم بر اساس مبالغ جديد اعلام شده در سایت شرکت(www.vipon.ir) محاسبه می‌شود. بدیهی است تغییر تعرفه با حداقل ترین درصد ممکن خواهد بود و مشمول خدماتی که مشتری آن‌ها را قبل از تغییر تعرفه خریداری کرده است نمی‌شود.',
      'ویپون سیاست تعرفه گذاری به نحوه اقتصادی را انتخاب کرده و أساس تعرفه گذاری در آینده سیستم به همین نحوه و بسا کمترین افزایش قیمت خواهد بود',
      'در صورتی که صاحب حساب هزینه اشتراک ماهانه را پرداخت نکند، ویپون حق دارد که تا زمان تصویه بدهی حساب کاربری را به حالت تعلیق درآورد.'
    ]
  },
  {
    id: 9,
    title: 'سایر شرايط',
    details: [
      'ویپون به صورت مستمر خدمات خود را توسعه و بهبود می‌بخشد. به همين علت در طول زمان امكاناتی به خدمات و نرم افزار اضافه و امكانات متناظر با آن‌ها تغيير يافته و يا حذف می‌شوند.',
      'ویپون حق اصلاح و تغيير شرایط و مقررات ذكر شده در اين سند، قيمت خدمات، محدوديت های فضای در دسترس مشتری و پهنای باند در دسترس مشتری يا ديگر منابعی را كه در اختيار مشتری قرار داده است دارد. خدماتی كه پيش از اعمال اصلاح و تغييرات توسط مشتريان خريداری شده است تا پايان زمان مصرف برای آن‌ها اعمال نمی‌شود. همواره آخرین نسخه این توافقنامه در آدرس اینترنتی www.vipon.irقرار دارد.',
      'هر گونه تسامح ویپون در پيگيری و اجرای حقوق خود در اين توافقنامه به معنی چشم پوشی از اين حقوق نيست.',
      'قوانين و مقررات ذكر شده در اين توافقنامه ارجح بر هر نوع توافق قبلی بين ویپون و مشتری است.'
    ]
  },
  {
    id: 10,
    title: 'فورس ماژور',
    details: [
      'در صورت بروز هر گونه حوادث غير مترقبه و ساير عوامل خارج از اختيار ویپون، از قبيل، اعتصاب، اغتشاش عمومی، صاعقه، آتش سوزی یا حوادث موثر در محيط و مانند اين‌ها، به نحوی که انجام تعهدات و خدمات اين توافقنامه را برای ما غيرممكن سازد و يا با اشكال مواجه نمايد، هيچگونه مسئوليتی در قبال عدم انجام تعهدات متوجه ویپون نمی‌باشد و مشتری حق هرگونه ادعايی نسبت به مجری را از خود سلب می‌نمايد. با اين حال در صورت بروز چنين مواردی ویپون تلاش خود برای کاهش اثرات اين موارد را می‌نمايد.',
      'تعدادی از شركت های ثالث، همانند شركتهای فراهم كننده ارتباطات شبكه ای جهت فراهم كردن خدمات با ویپون همكاری می‌كنند، هر نوع قطع شدن خدمات از طرف اين تامين كننده‌ها ممكن است كه خدمات ویپون را به صورتی كه تحت كنترل ویپون نباشد دچار اختلال كند.'
    ]
  }
]
</script>

<template>
  <div class="rules">
    <div class="rule" v-for="rule in rules" :key="rule['title']">
      <h2 class="rule__title">
        ماده <span>{{ rule['id'] }}:</span>
        {{ rule['title'] }}
      </h2>
      <p
        class="rule__detail"
        v-for="(detail, idx) in rule['details']"
        :key="detail"
      >
        <span>{{ rule['id'] + '-' + (idx + 1) }})</span> {{ detail }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rules {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rule {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 20px;
  margin-bottom: 20px;
}
.rule__title {
  width: 100%;
  color: #000;
  font-size: 18px;
  margin-bottom: 15px;
}
.rule__detail {
  margin-bottom: 12px;
  color: #777;
  text-align: justify;
  line-height: 22px;
}
</style>
