import http from './HttpService'

const baseURL = 'https://api.vipon.ir/api/v2'

export const sendLoginData = ({ mobile }) => {
  const Data = { MOBILE: 'mobile' }
  const formData = {}
  formData[Data.MOBILE] = mobile

  return http.post('/login/store/', formData, {
    baseURL
  })
}

export const sendConfirmData = ({ code, mobile }) => {
  const Data = { MOBILE: 'mobile', CODE: 'code' }
  const formData = {}
  formData[Data.MOBILE] = mobile
  formData[Data.CODE] = code

  return http.post('/login/store/verify/', formData, {
    baseURL
  })
}

export const getTokens = ({ mobile, token, store }) => {
  const Store = {
    MOBILE: 'mobile',
    STORE: 'store_id',
    TOKEN: 'token'
  }

  const formData = {}
  formData[Store.MOBILE] = mobile
  formData[Store.STORE] = store
  formData[Store.TOKEN] = token

  return http.post('/login/store/getToken/', formData, {
    baseURL
  })
}
